/* Form */
.ant-form-item .ant-form-item-label > label {
  flex-direction: row-reverse;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  margin-inline-end: 0px;
  margin-inline-start: 4px;
}

.ant-form-item:last-child {
  margin-bottom: 0px;
}

/* Datepicker */
.custome-date-picker-icon .ant-picker > div {
  order: 2;
}

.custome-date-picker-icon .ant-picker .ant-picker-suffix {
  margin-inline-end: 8px;
  margin-inline-start: 0px;
}
