.service-section-main .next-btn {
  border-radius: 12px;
  border: none;
  background: linear-gradient(
    120deg,
    #3259d8 -30%,
    #3a62da -10%,
    #744aba 20%,
    #bd3a99 65%,
    #f42d80 90%,
    #ff2b7b 120%
  );
  color: #fff;
  height: 35px;
  cursor: pointer;
}

.customer-service-vindo-wave {
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  margin-block: 18px 24px;
  overflow: visible;
}

.customer-service-vindo-holder {
  width: 110px;
  height: 110px;
  background: linear-gradient(
    120deg,
    #3259d8 -30%,
    #3a62da -10%,
    #744aba 20%,
    #bd3a99 65%,
    #f42d80 90%,
    #ff2b7b 120%
  );
  border-radius: 50%;
  overflow: hidden;
}

.customer-service-vindo {
  width: 100%;
  height: 100%;
  background: #ffffff66;
  border-radius: 50%;
  overflow: hidden;

  padding: 6px;
}

.customer-service-image {
  background: #fff;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
