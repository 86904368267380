/* Colors */
.pc {
  color: var(--prim-color) !important;
}

.sc {
  color: var(--sec-color) !important;
}

.gc {
  color: #8e8e93 !important;
}

.wc {
  color: #ffffff !important;
}

.bc {
  color: #000000 !important;
}

/* Spacing (padding & margin) */
.pl-1 {
  padding-inline-start: 1rem;
}

.pr-1 {
  padding-inline-end: 1rem;
}

.pt-1 {
  padding-block-start: 1rem;
}

.pb-1 {
  padding-block-end: 1rem;
}

.ml-1 {
  margin-inline-start: 1rem;
}

.mr-1 {
  margin-inline-end: 1rem;
}

.mt-1 {
  margin-block-start: 1rem;
}

.mb-1 {
  margin-block-end: 1rem;
}

/* Sizing (Width & Height) */
.w-50 {
  width: 50% !important;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

/* Font (Size, Weight) */
.main-header {
  font-size: 2.5rem !important;
}

.sub-header {
  font-size: 2rem !important;
}

.description-text {
  font-size: 1rem !important;
}

.fz-10 {
  font-size: 0.625rem !important;
}

.fz-12 {
  font-size: 0.75rem !important;
}

.fz-14 {
  font-size: 0.875rem !important;
}

.fz-16 {
  font-size: 1rem !important;
}

.fz-18 {
  font-size: 1.125rem !important;
}

.fz-20 {
  font-size: 1.25rem !important;
  line-height: 1.25rem !important;
}

.fz-22 {
  font-size: 1.375rem !important;
}

.fz-24 {
  font-size: 1.5rem !important;
}

.fz-26 {
  font-size: 1.6rem !important;
  line-height: 2.2rem !important;
}

.fz-28 {
  font-size: 1.75rem !important;
}

.fz-32 {
  font-size: 2rem !important;
  line-height: 45px !important;
}

.fz-36 {
  font-size: 2.1rem !important;
  line-height: 2.4rem !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

/* Placement */
.center-items {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* Custom */
.clickable {
  cursor: pointer;
}

.underline:hover {
  text-decoration: underline;
}

.table-bottom-info {
  color: #a3a6b4 !important;
  position: absolute !important;
  left: 0;
  bottom: 20px;
}

@media only screen and (max-width: 1366px) {
  .fz-16 {
    font-size: 0.9rem !important;
  }

  .fz-18 {
    font-size: 1rem !important;
  }

  .fz-20 {
    font-size: 1.125rem !important;
    line-height: 1.25rem !important;
  }

  .fz-22 {
    font-size: 1.25rem !important;
  }

  .fz-24 {
    font-size: 1.375rem !important;
  }

  .fz-26 {
    font-size: 1.5rem !important;
  }

  .fz-28 {
    font-size: 1.6rem !important;
  }

  .fz-32 {
    font-size: 1.75rem !important;
    line-height: 2rem !important;
  }

  .fz-36 {
    font-size: 1.8rem !important;
    line-height: 2.2rem !important;
  }
}
